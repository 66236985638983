@import "src/styles/colors.scss";
@import "src/styles/media.scss";

* {
  font-family: "Open Sans", Sans-serif;
  color: $grey;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.orders .full-price .next-button {
  padding: 4px;
  border: 2px solid $orange;
  border-radius: 6px;
  color: $orange;
  outline: none;
  transition: .31s ease-in color,
              .31s ease-in background-color;
  cursor: pointer;

  &:hover {
    background-color: $orange;
    color: $white;
  }

  &.disabled {
    border-color: $grey;
    color: $grey;
    cursor: not-allowed;
    
    &:hover {
      border-color: $red;
      background-color: $red;
      color: $white;
    }
  }
}

.info2 {
  width: 740px;
  margin: 0 auto;
  font: 700 20px/32px sans-serif;
  text-align: center;

  @include media-down {
    width: calc(100vw - 24px);
    font-size: 14px;
    line-height: 20px;
  }

  .next-button {
    width: 56px;
    line-height: 20px;
    padding: 4px;
    border: 2px solid $orange;
    border-radius: 6px;
    color: $orange;
    outline: none;
    transition: .31s ease-in color,
                .31s ease-in background-color;
    cursor: pointer;
  
    &:hover {
      background-color: $orange;
      color: $white;
    }
  }

  
  > div {
    flex: 1 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 700 18px/32px sans-serif;

    @include media-down {
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;

      img {
        width: 100%;
      }
    }
  }
}

.infocircle {
  &.small {
    position: relative;
    top: 18px;
    margin: 0 10px;
    width: 30px;
    height: 30px;

    @include media-down {
      width: 24px;
      margin-bottom: -32px;
      left: calc(100% - 52px);
      top: unset;
      bottom: 38px;
    }
  }

  @include media-down {
    width: 34px;
  }

  margin-left: 8px;
  cursor: pointer;
  
  .circle {
    transition: .21s ease-out fill;
    stroke: $orange;
    fill: transparent
  }
  
  .info {
    transition: .21s ease-out fill;
    fill: $orange;
  }

  &:hover {
    .circle {
      fill: $orange;
    }

    .info {
      fill: $white;
    }
  }
}

.app {
  position: relative;
  overflow: auto;
  height: 100vh;

  .scroller {
    margin-bottom: 20px;
    padding-bottom: 40px;
  }

  .modal {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    padding: 40px calc(max(50vw - 500px, 40px));
    background-color: $blue-shadow;
    pointer-events: none;
    transition: .37s ease-in opacity;

    &.mobile-select {
      display: none;

      @include media-down {
        display: initial;
      }

      .modal-container {
        height: 100%;
        overflow: hidden;

        > :last-of-type {
          height: 100%;
        }
      }

      .select-title {
        font: 700 20px/26px sans-serif;
        text-align: center;
      }

      .select-box {
        height: calc(100% - 20px);
        overflow-y: auto!important;
        
        &.autocomplete {
          height: calc(100% - 60px);
        }

        .select-item {
          display: flex;
          align-items: center;
          height: 40px;
          font: 700 16px/40px sans-serif;

          &.selected {
            background-color: $orange-shadow;
          }
          
          + .select-item {
            border-top: 1px solid $orange;
          }
        }
      }

      label {
        display: block;
        position: relative;
        margin-bottom: 8px;
        font: 500 13px/19px sans-serif;

        &.error {
          input {
            border: 2px solid $red!important;
            margin: -1px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 2px;
          bottom: 0;
          left: 2px;
          height: 2px;
          background-color: $orange;
          transform: scale(0);
          transition: .21s ease-out transform;
        }

        &:focus-within {
          ::after {
            transform: scale(1);
          }
        }

        input[type="text"] {
          box-sizing: border-box;
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid $black;
          border-radius: 4px;
          font: 500 16px/26px sans-serif;
  
          &:hover, &:focus {
            border-color: $orange;
          }
        }
      }
    }

    @include media-down {
      padding: 0;
    }

    .info-modal {
      img {
        width: 100%;
      }

      .main-header {
        font: 700 26px/40px sans-serif;
        text-align: center;
      }

      .info-modal-switch{
        display: flex;

        > div {
          flex: 1 1;
          border: 2px solid $orange;
          color: $orange;
          padding: 4px;
          border-radius: 4px;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          cursor: pointer;
          transition: .31s ease-out color,
                      .31s ease-out background-color;

          &:hover, &.active {
            background-color: $orange;
            color: $white;
          }

          + div {
            margin-left: 20px;
          }
        }
      }

      .header {
        font: 700 20px/40px sans-serif;
      }

      .inner {
        margin-left: 30px;

        &::before {
          content: "- ";
        }
      }

      div {
        line-height: 26px;
      }

      .streets {
        display: flex;

        @include media-down {
          flex-direction: column;
        }

        > div {
          flex: 1 1;

          +div {
            margin-left: 8px;

            @include media-down {
              margin-left: 0;
            }
          }
        }
      }
    }

    .close {
      position: absolute;
      top: -5px;
      right: 5px;
      font-size: 24px;
      font-weight: 900;
      cursor: pointer;
      color: $blue-shadow;
      transition: .3s color;

      @include media-down {
        position: sticky;
        margin-bottom: -37px;
        text-align: right;
        z-index: 1000000;
        width: 24px;
        margin-left: auto;
      }

      &:hover {
        color: $blue;
      }
    }
    
    &.active {
      opacity: 1;
      pointer-events: all;


      .modal-container {
        box-shadow: 0 0 6px 1px $blue-shadow;
        transform: translateY(0);
        opacity: 1;
      }
    }
    
    .modal-container {
      box-sizing: border-box;
      width: 100%;
      max-height: 100%;
      padding: 20px;
      background-color: $white;
      border: 4px solid $orange;
      border-radius: 8px;
      transform: translateY(-100vh);
      opacity: 0;
      overflow: auto;
      transition: .37s ease-in opacity,
                  .37s ease-out transform,
                  .37s ease-out max-height;
      
      @include media-down {
        padding: 8px;
        border-radius: 0;
      }

      .delivery-details .full-price {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font: 700 18px Sans-serif;

        :first-child {
          margin-right: auto;
        }

        :nth-child(3) {
          margin-left: auto;
          margin-right: 10px;

          @include media-down {
            margin-left: unset;
            margin-right: unset;
            font-size: 17px;
          }
        }

        .next-button {
          padding: 4px;
          border: 2px solid $orange;
          border-radius: 6px;
          color: $orange;
          outline: none;
          transition: .31s ease-in color,
                      .31s ease-in background-color;
          cursor: pointer;

          @include media-down {
            &:first-of-type {
              position: relative;
              bottom: -72px;
              left: calc(-50% + 36px);
              margin-left: auto;
            }
            &:last-of-type {
              margin: 6px 0 6px auto;
            }
          }

          &.disabled {
            position: relative;
            border-color: $grey;
            color: $grey;
            cursor: not-allowed;

            &.price::after {
              content: "Minimum kiszállítási összeg 2800 Ft";
            }

            &::after {
              content: "Töltse ki a *-al jelölt mezőket";
              position: absolute;
              top: -31px;
              right: 0;
              width: max-content;
              padding: 4px;
              border: 1px solid black;
              border-radius: 4px;
              background-color: white;
              font: 500 13px sans-serif;
              color: black;
              transform: scale(0) translateX(50%);
              opacity: 0;
              transition: .31s .5s ease-out transform,
                          .31s .5s ease-out opacity;
            }

            &.non::after {
              display: none;
            }
            
            &:hover {
              border-color: $red;
              background-color: $red;
              color: $white;

              &::after {
                transform: scale(1) translateX(0);
                opacity: 1;
              }
            }
          }

          &:hover {
            background-color: $orange;
            color: $white;

            .spinner path {
              stroke: $white;
            }
          }

          .spinner {
            margin: -2px 0 -6px 7px;
            height: 24px;
            width: 24px;
      
            path {
              stroke: $orange;
            }
            
            .clockwise {
              transform-origin: center center;
              animation: .91s rotateCW infinite;
            }
            
            .anticlockwise {
              transform-origin: center center;
              animation: 1.51s rotateCCW infinite;
            }
          }
        }
      }   
    }

    .delivery-details {
      margin: -8px 0;

      form > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-down {
          flex-direction: column;
        }
      }

      label {
        display: inline-block;
        position: relative;
        margin-bottom: 8px;
        flex: 1 1;
        font: 500 13px/19px sans-serif;

        p {
          margin-bottom: -16px;

          @include media-down {
            margin-bottom: 0;
          }
        }

        + label {
          margin-left: 12px;

          @include media-down {
            margin-left: 0;
          }
        }

        &.error {
          input {
            border: 2px solid $red!important;
            margin: -1px;
          }
        }

        .auto-complete-container {
          position: absolute;
          left: 0;
          right: 0;
          top: 45px;
          z-index: 1;
          max-height: 100px;
          padding: 8px;
          border: 1px solid $orange;
          border-top: none;
          border-radius: 0 0 4px 4px;
          background: white;
          overflow-y: auto;
          box-shadow: 0 7px 9px -2px $orange;
          transition: .21s ease-out opacity,
                      .21s ease-out transform;
          opacity: 0;
          transform: scaleX(0);

          @include media-down {
            display: none;
          }

          .auto-complete-item {
            transition: .21s ease-out background-color;
            padding: 2px;

            +.auto-complete-item {
              border-top: 1px solid $orange;
            }

            &:hover {
              background-color: $orange-shadow;
            }
          }
        }

        textarea {
          box-sizing: border-box;
          display: block;
          width: 100%;
          padding: 4px;
          outline: none;
          border: 1px solid $black;
          border-radius: 4px;
          font: 500 16px/24px sans-serif;
  
          &:hover, &:focus {
            border-color: $orange;
          }
        }

        select {
          box-sizing: border-box;
          display: block;
          width: 100%;
          padding: 4px;
          outline: none;
          border: 1px solid $black;
          border-radius: 4px;
          font: 500 16px/26px sans-serif;
          background: $white;

          @include media-down {
            option {
              display: none;
            }
          }
  
          &:hover, &:focus {
            border-color: $orange;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 2px;
          bottom: 0;
          left: 2px;
          height: 2px;
          background-color: $orange;
          transform: scale(0);
          transition: .21s ease-out transform;
        }

        &:focus-within {
          ::after {
            transform: scale(1);
          }

          .auto-complete-container {
            opacity: 1;
            transform: scaleX(1);
          }
        }

        input[type="text"] {
          box-sizing: border-box;
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid $black;
          border-radius: 4px;
          font: 500 16px/26px sans-serif;
  
          &:hover, &:focus {
            border-color: $orange;
          }
        }
      }
    }

    .orders {
      flex: 1 1;
      padding-right: 20px;
      overflow: auto;

      @include media-down {
        padding-right: 0;
      }

      .item-original-price {
        font: 700 15px/20px sans-serif;
        margin-left: auto;

        @include media-down {
          margin-left: 0;
          font-size: 13px;
        }
      }
      
      .item-full-price {
        font: 700 15px/20px sans-serif;
        margin-right: 10px;

        @include media-down {
          margin-right: 6px;
          font-size: 13px;
        }
      }
      
      .full-price {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font: 700 18px Sans-serif;

        @include media-down {
          font-size: 14px;
        }

        :first-child {
          margin-left: auto;
          margin-right: 10px;
        }
      }

      .orders-title {
        font: 900 18px/20px Sans-serif;
        margin-bottom: 10px;
        letter-spacing: -.4px;
      }
      
      .ordered-item {
        display: flex;
        justify-content: space-between;
        margin: 4px 0 2px;
        padding-bottom: 2px;
        border-bottom: 1px solid $orange;

        @include media-down {
          position: relative;
        }

        &.error {
          height: 60px;
          align-items: center;
          justify-content: center;
          background-color: $orange;
          font: 700 26px/115px sans-serif;
          color: white;

          @include media-down {
            padding: 0 8px;
            font-size: 13px;
            line-height: 19px;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }

        .ordered-item-content {
          padding-right: 8px;
          flex: 1 1;

          @include media-down {
            padding-right: 0;
          }

          label {
            position: relative;
            
            &::after {
              content: "";
              position: absolute;
              right: 2px;
              bottom: 3px;
              left: 2px;
              height: 2px;
              background-color: $orange;
              transform: scaleX(0);
              transition: .31s ease-in transform;
            }

            &:focus-within::after {
              transform: scaleX(1);
            }
          }

          textarea {
            box-sizing: border-box;
            width: 100%;
            max-height: 37px;
            height: 100%;
            outline: transparent;
            transition: .31s ease-out border-color,
                        .31s max-height;
            border: 1px solid black;
            border-radius: 4px;
            resize: none;
            outline: none;

            &:hover, &:focus {
              border-color: $orange;
            }

            &:focus {
              max-height: 70px;
              height: 70px;
            }
          }
        }

        .ordered-item-header {
          display: flex;
          margin-bottom: 4px;
          font: 700 18px/20px Sans-serif;
          letter-spacing: -.6px;

          @include media-down {
            display: block;
            font-size: 15px;
            line-height: 21px;
            width: calc(100% - 64px);
          }
        }

        img {
          width: 105px;
          height: 60px;
          object-fit: cover;

          @include media-down {
            position: absolute;
            right: 0;
            width: 62px;
            height: auto;
          }
        }

        .remove-cart {        
          width: 24px;
          height: 24px;
          margin: -3px -1px;
          padding: 1px;
          border-radius: 4px;
          transition: .27s ease-out background-color;
          cursor: pointer;
          
          &:hover {
            background-color: $red;
            
            path, line {
              stroke: $white;
            }
          }
          
          path, line {
            stroke: $red;
            transition: .27s ease-out stroke;
          }
        }

        .action-row-buttons {
          display: flex;
          align-items: center;
          height: 16px;
          margin-right: 4px;
          margin-left: 6px;
          border: 2px solid $orange;
          border-radius: 4px;
    
          div {
            height: 16px;
          }
    
          .calc-container {
            transition: .27s ease background-color;
            cursor: pointer;
            outline: none;
            margin: -1px 0 -1px -1px;
            padding: 1px;
            
            &:last-of-type {
              margin: -1px -1px -1px 0;
            }

            &:hover {
              background-color: $orange;
    
              .calc line {
                stroke: $white;
              }
            }

            .calc {
              width: 14px!important;
              height: 14px!important;
              padding: 1px;
    
              line {
                transition: .27s ease stroke;
                stroke: $orange;
              }
            }
          }
    
          .counter {
            margin: 0 4px;
            color: $orange;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            text-align: center;
          }
    
        }
      }
    }

    .contact {
      flex: 1 1;
    }
  }
}

.main-img-container {
  position: relative;
  margin-bottom: -4px;

  .svg-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 20%;
    left: 0;
    align-items: center;
    justify-content: center;

    svg {
      width: 58.5vw;
      stroke: $white;
      stroke-dasharray: 475;
      stroke-dashoffset: 475;
      
      path {
        fill: transparent;
        animation: forwards 1s 1s backgroundIn, forwards 3s logoAnim;
      }

      > g > path:first-of-type {
        animation: forwards 1s 1s backgroundIn, forwards 1.8s logoAnim;
      }

    }
  }

  .main-img {
    width: 100%;
  }
}

nav {
  display: flex;
  position: sticky;
  top: 0;
  height: 60px;
  align-items: center;
  margin-bottom: -60px;
  padding: 0 calc(max(40px, 50vw - 500px));
  z-index: 10;

  @include media-down {
    height: 46px;
    padding: 0 8px;
    margin-bottom: 0px;
  }

  .link {
    margin-right: 8px;
    margin-left: auto;
    position: relative;
    padding: 0 4px;
    font-size: 24px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    color: $orange;
    cursor: pointer;
    outline: none;

    @include media-down {
      font-size: 20px;
    }
  
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: $orange;
      transform: scale(0) translateX(30%);
      transition: .31s ease transform;
    }

    &:hover::after {
      transform: scale(1) translateX(0%);
    }
  }

  .navigation-types {
    width: 85%;
    display: flex;
    flex-wrap: wrap;

    @include media-down {
      display: none;
    }

    &-mobile {
      display: none;

      @include media-down {
        display: initial;
      }

      .nav-icon {
        height: 34px;
        outline: none;

        svg {
          width: 34px;
          height: 34px;
  
          path, rect {
            stroke: $orange;
          }
        }
      }

      .nav-data {
        height: 100vh;
        position: absolute;
        width: 250px;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 6px 8px;
        background: #013c5a;
        transform: translateX(-280px);
        transition: .31s ease-out transform;
        box-shadow: 0 0 4px 4px #00000060;
        z-index: 10;

        &.active {
          transform: translateX(0);
        }

        .nav-icon {
          margin-bottom: 6px;
        }

        div:not(.nav-icon) {
          color: white;
          padding: 6px;
          outline: none;

          + div {
            border-top: 1px solid white;
          }
        }
      }
    }

    > *:not(.background) {
      flex: 0 1 18%;
      
      div {
        display: inline-block;
        position: relative;
        padding: 0 4px;
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
  
      
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: -2px;
          left: 0;
          height: 2px;
          background-color: $white;
          transform: scale(0) translateX(30%);
          transition: .31s ease transform;
        }
    
        &:hover::after {
          transform: scale(1) translateX(0%);
        }
      }

  
      + * {
        margin-left: 8px;

        &:nth-of-type(6) {
          padding-top: 4px;
          margin-left: 0;
        }
        
        @for $i from 7 through 10 {
          &:nth-of-type(#{$i}) {
            padding-top: 4px;
          }
        }
      }
    }
  }
  
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -40px;
    z-index: -1;
    background-color: $blue;
    opacity: 0;
    transition: .31s ease-in opacity;
  }

  &.navigation .background {
    opacity: 1;
  }

  .cart { 
    position: relative;
    outline: none;
    cursor: pointer;
    border-radius: 4px;

    @include media-down {
      height: 34px;
    }

    svg {
      width: 48px;
      height: 48px;

      @include media-down {
        width: 34px;
        height: 34px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: $orange;
      transform: scale(0) translateX(30%);
      transition: .31s ease transform;
    }

    &:hover::after {
      transform: scale(1) translateX(0%);
    }
    
    path, line {
      stroke: $orange;

      &.number {
        fill: $orange;
      }
    }
  }
}

@keyframes logoAnim {
  0% {
    stroke-dashoffset: 475;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes backgroundIn {
  0% {
    fill: transparent;
  }
  100% {
    fill: $white;
  }
}

@keyframes rotateCW {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes rotateCCW {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-360deg);
  }
}
