@import "src/styles/colors.scss";
@import "src/styles/media.scss";

.item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding-left: 14px;
  border-radius: 8px;
  box-shadow: 0 0 4px -1px $blue-shadow;
  transition: .15s ease box-shadow;
  overflow: hidden;

  @include media-down {
    padding-left: 6px;
  }

  &:hover {
    box-shadow: 0 0 4px 1px $blue-shadow;
  }

  .item-content {
    margin: 6px 0;
    flex: 1 1;

    .item-text {
      @include media-down {
        min-height: 40px;
        margin-bottom: 7px;
        width: calc(100% - 86px);
      }
    }

    img {
      display: none;
      position: absolute;
      width: 82px;
      height: 42px;
      right: 0;
      top: 0;
      border-top-right-radius: 6px;
      object-fit: cover;

      @include media-down {
        display: initial;
      }
    }
  }

  .item-name {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    font: 700 24px/40px Sans-serif;

    @include media-down {
      font-size: 18px;
      line-height: 24px;
    }

    :last-child {
      color: $orange;
      min-width: 100px;

      @include media-down {
        display: none;
      }

      &.after {
        position: relative;
        z-index: -1;
        line-height: 24px;

        &.extra {
          &::after {
            content: "(A menü extra ára)";
          }

          &::before {
            content: "+";
          }
        }

        &::after {
          position: absolute;
          content: "(A menü alap ára)";
          right: 0;
          top: 21px;
          width: max-content;
          font: 400 11px/16px sans-serif;
          text-align: center;
        }
      }
    }
  }

  .item-description {
    margin-bottom: 7px;
    font: 500 italic 16px/20px Sans-serif;

    @include media-down {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .action-row {
    display: flex;
    margin-bottom: 4px;

    @include media-down {
      margin-right: 6px;
    }

    .action-row-buttons {
      display: flex;
      align-items: center;
      border: 4px solid $blue;
      border-radius: 8px;

      div {
        height: 40px;

        @include media-down {
          height: 27px;
        }
      }

      .calc-container {
        transition: .27s ease background-color;
        cursor: pointer;
        outline: none;
        padding: 1px;
        margin: -1px;

        &:hover {
          background-color: $blue;

          .calc line {
            stroke: $white;
          }
        }

        &:last-of-type {
          .calc {
            margin: 8px 12px;

            @include media-down {
              margin: 6px;
            }
          }
        }

        .calc {
          width: 24px;
          height: 24px;
          margin: 8px 12px 8px 8px;
          
          @include media-down {
            width: 16px;
            height: 16px;
            margin: 6px;
          }

          line {
            transition: .27s ease stroke;
            stroke: $blue;
          }
        }
      }

      .counter {
        width: 40px;
        color: $blue;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;

        @include media-down {
          width: 30px;
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    .price {
      display: none;
      color: $orange;
      font: 700 20px/34px Sans-serif;
      margin-left: auto;

      @include media-down {
        display: initial;
      }

      &.after {
        position: relative;
        z-index: -1;

        &.extra {
          &::after {
            content: "(A menü extra ára)";
          }

          &::before {
            content: "+";
          }
        }

        &::after {
          position: absolute;
          content: "(A menü alap ára)";
          right: 0;
          top: 28px;
          width: max-content;
          font: 400 11px/16px sans-serif;
          text-align: center;
        }
      }
    }

    .cart {
      margin-left: 8px;
      padding-left: 2px;
      border: 4px solid $orange;
      border-radius: 8px;
      transition: .27s ease background-color;
      outline: none;
      cursor: pointer;

      @include media-down {
        width: 30px;
        height: 30px;
      }

      &:hover {
        background-color: $orange;

        path, line {
          stroke: $white;
        }
      }

      path, line {
        stroke: $orange;
        transition: .27s ease stroke;
      }
    }
  }
  
  img {
    width: 230px;
    height: 131px;
    object-fit: cover;
    cursor: pointer;

    @include media-down {
      display: none;
    }
  }
}
