@import "src/styles/colors.scss";
@import "src/styles/media.scss";

.item-container {
  position: relative;
  margin: 0 0 60px;
  padding: 0 calc(max(50vw - 400px, 40px));

  .item-container-title {
    position: sticky;
    top: 60px;
    margin: 0 calc(min(-50vw + 400px, -40px));
    padding: 0 calc(max(50vw - 400px, 40px));
    background-color: $white;
    font: 900 32px/60px Sans-serif;
    letter-spacing: 1.7px;
    text-transform: capitalize;
    box-shadow: 0 7px 10px -5px $blue-shadow;
    z-index: 1;

    @include media-down {
      height: 40px;
      top: 46px;
      margin: 0 -8px;
      padding: 8px;
      font-size: 24px;
      line-height: 40px;
    }
  }

  @include media-down {
    padding: 0 8px;
  }
}
