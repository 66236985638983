@import "src/styles/colors.scss";
@import "src/styles/media.scss";

.pics-container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.pics {
  box-sizing: border-box;
	width: 100vw;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  .all-pics {
    position: relative;
    flex: 0 0 30%;
    height: fit-content;
    border: 2px solid $orange;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 8px;
    cursor: pointer;
    margin-bottom: 8px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover::after {
      opacity: 0;
    }

    &::after {
      content: "";
      opacity: .3;
      background: $white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: .21s ease-out opacity;
    }

    img {
      width: 100%;
      margin-bottom: -4px;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: .31s ease-out transform;
    background-color: $blue-shadow;
    z-index: 100;

    &.active {
      transform: scale(1);
    }

    img {
      min-width: 50vw;
      min-height: 50vh;
      max-width: 80vw;
      max-height: 80vh;
    }

    .container {
      display: flex;
      border: 4px solid $orange;
      border-radius: 8px;
      overflow: hidden;

      .arrow {
        display: flex;
        align-items: center;
        background-color: $white;
        font-size: 40px;
        font-weight: 900;
        padding: 6px;
        transition: .27s ease-out background-color;

        &:hover {
          background-color: $orange;

          p {
            color: $white;
          }
        }

        p {
          color: $orange;
          transition: .27s ease-out color;
        }
      }
    }
  }
}
